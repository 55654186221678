import React from "react"
import BlockWrapper from "../../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-solid-svg-icons"

// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/scss"

const SliderGalleryBlock = ({ block, forceLoadImages = false }) => {
  return (
    <BlockWrapper block={block}>
      <div className={`relative ${block.headline?.length > 0 ? "pt-8" : ""}`}>
        <div
          className={`absolute top-0 right-0 flex flex-row pb-2 transform -translate-y-full md:pb-10`}
        ></div>
        <div
          className={`absolute z-50 top-1/2 left-0 transform -translate-x-full -translate-y-full text-2xl flex items-center justify-center w-8 h-8 mr-4 text-primary hover:text-primary-hover swiper-button-prev-${block._uid}`}
        >
          <FontAwesomeIcon icon={faCaretLeft} size="2x" className="" />
        </div>
        <div
          className={`absolute z-50 top-1/2 right-0 transform translate-x-full -translate-y-full text-2xl flex items-center justify-center w-8 h-8 text-primary  hover:text-primary-hover swiper-button-next-${block._uid}`}
        >
          <FontAwesomeIcon icon={faCaretRight} size="2x" className="" />
        </div>

        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={32} // 0 vs 32px
          slidesPerView={1} // auto vs {1}
          pagination={{
            clickable: true,
            el: ".swiper-pagination-" + block._uid,
            bulletClass:
              "swiper-pagination-bullet inline-block w-4 h-4 mx-2 border md:w-6 md:h-6 border-primary hover:bg-primary",
            bulletActiveClass: "bg-primary",
          }}
          navigation={{
            disabledClass:
              "bg-white border-gray-300 text-gray-300 pointer-events-none",
            nextEl: ".swiper-button-next-" + block._uid,
            prevEl: ".swiper-button-prev-" + block._uid,
          }}
        >
          {block.image_items &&
            block.image_items.length > 0 &&
            block.image_items.map((item, index) => (
              <SwiperSlide key={index} className="mb-4">
                <figure className="xpx-grid" key={index}>
                  <div className="block w-full h-full text-center">
                    <div className="block w-full h-full bg-gray-200 ">
                      <Image
                        image={item.image}
                        className="object-contain w-full h-full"
                        aspectRatio="16by9"
                        forceLoad={forceLoadImages}
                      />
                    </div>
                  </div>
                  {(item.caption?.length > 0 ||
                    item.image?.title?.length > 0 ||
                    item.image?.copyright?.length > 0) && (
                    <div className={`-mx-grid`}>
                      <figcaption className="w-full max-w-[960px] mx-auto mt-4">
                        {item.caption?.length > 0 ? (
                          <span className="mr-4">{item.caption}</span>
                        ) : item.image?.title?.length > 0 ? (
                          <span className="mr-4">{item.image.title}</span>
                        ) : (
                          ""
                        )}
                        {item.image?.copyright?.length > 0 && (
                          <small>© {item.image.copyright}</small>
                        )}
                      </figcaption>
                    </div>
                  )}
                </figure>
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          className={`swiper-pagination-${block._uid} text-center py-2`}
        ></div>
      </div>
    </BlockWrapper>
  )
}

export default SliderGalleryBlock
